<template>
  <el-dialog
    append-to-body
    :before-close="handleClose"
    :title="title"
    :visible.sync="dialogVisible"
    width="40%"
  >
    <Form :column="column" :label-width="100" :query="query" />
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="onSubmit">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { batchUserDelete } from '@/api/user'
  import Form from '@/components/Form'
  export default {
    components: {
      Form,
    },
    data() {
      return {
        title: null,
        dialogVisible: false,
        loading: false,
        row: {},
        query: {
          user_ids: null,
        },
        column: Object.freeze([
          {
            label: 'UID',
            prop: 'user_ids',
            type: 'textarea',
            tips: 'UID用英文逗号隔开',
          },
        ]),
      }
    },
    methods: {
      handleOpen(title, row) {
        this.title = title
        this.row = row || {}
        this.dialogVisible = true
      },
      // 提交
      async onSubmit() {
        if (this.loading) return
        this.loading = true
        let params = Object.assign({}, this.query)
        let res
        if (this.title == '批量删除') {
          res = await batchUserDelete(params)
        }
        this.loading = false
        if (res.success) {
          this.cancel()
          this.$emit('getTableData')
          this.$message.success('操作成功')
        } else {
          this.$message.error('操作失败')
        }
      },
      handleClose(done) {
        done()
        this.cancel()
      },
      // 取消
      cancel() {
        this.dialogVisible = false
        setTimeout(() => {
          for (let key in this.query) {
            this.query[key] = null
          }
        }, 500)
      },
    },
  }
</script>
