const config = {
  query: {
    type: 1,
    searchType: 1,
    registerType: 1,
    user_id: '',
    keywords: '',
    role_end_time: null,
  },
  userList: [],
  maxHeight: document.body.clientHeight - 340,
  columnList: Object.freeze([
    { label: 'UID', prop: 'user_id', width: 80 },
    { label: '姓名', slotName: 'username' },
    { label: '最后登录IP', slotName: 'login_ip', width: 200 },
    { label: '手机号', slotName: 'phone', width: 150 },
    { label: '用户状态', slotName: 'status_str', width: 80 },
    {
      label: '操作',
      isButton: true,
      width: 450,
      btnList: [
        {
          label: '禁言',
          type: 'primary',
          eventName: 'handleMute',
          showName: 'isBanned',
          showStatusList: [false],
        },
        // {
        //   label: '取消禁言',
        //   type: 'danger',
        //   eventName: 'handleCancelMute',
        //   showName: 'isBanned',
        //   showStatusList: [true],
        // },
        // {
        //   label: '禁止私聊',
        //   type: 'primary',
        //   eventName: 'handleMute',
        //   showName: 'banned_status',
        //   showStatusList: [true],
        // },
        // {
        //   label: '恢复私聊',
        //   type: 'danger',
        //   eventName: 'handleCancelMute',
        //   showName: 'banned_status',
        //   showStatusList: [false],
        // },
        // {
        //   label: '添加标签',
        //   type: 'primary',
        //   eventName: 'previewUse',
        // },
        // {
        //   label: '更多操作',
        //   type: 'primary',
        //   eventName: 'previewUse',
        // },
      ],
    },
  ]),
  // 按钮列表
  buttonList: Object.freeze([
    // {
    //   label: '添加标签',
    //   type: 'primary',
    //   icon: 'el-icon-plus',
    //   eventName: 'handleAddLabel',
    // },
    // {
    //   label: '设为马甲',
    //   type: 'primary',
    //   icon: 'el-icon-s-check',
    //   eventName: 'handleSetVest',
    // },
    // {
    //   label: '设为管理员',
    //   type: 'primary',
    //   icon: 'el-icon-user-solid',
    //   eventName: 'handleSetAdmin',
    // },
    {
      label: '删除',
      type: 'danger',
      icon: 'el-icon-delete',
      eventName: 'handleRemove',
    },
    {
      label: '批量禁言',
      type: 'primary',
      icon: 'el-icon-turn-off-microphone',
      eventName: 'handleBulkMute',
    },
    {
      label: '批量删除',
      type: 'danger',
      icon: 'el-icon-delete',
      eventName: 'handleBatchRemove',
    },
    // {
    //   label: '重置资料',
    //   type: 'primary',
    //   icon: 'el-icon-refresh',
    //   eventName: 'handleResetData',
    // },
    // {
    //   label: '导入用户',
    //   type: 'primary',
    //   icon: 'el-icon-folder-add',
    //   eventName: 'handleImport',
    // },
  ]),
  typeSelect: Object.freeze([
    {
      id: 1,
      label: '全部账号',
    },
    {
      id: 2,
      label: '马甲账号',
    },
    {
      id: 3,
      label: '管理员账号',
    },
    {
      id: 4,
      label: '禁言账号',
    },
    {
      id: 5,
      label: '标签用户',
    },
  ]),
  searchTypeSelect: Object.freeze([
    {
      id: 1,
      label: '用户名',
    },
    {
      id: 2,
      label: 'UID',
    },
    {
      id: 3,
      label: '手机号',
    },
  ]),
  registerSelectList: Object.freeze([
    {
      id: 1,
      label: '全部状态',
    },
    {
      id: 2,
      label: '已付费',
    },
    {
      id: 3,
      label: '未付费',
    },
    {
      id: 4,
      label: '老用户未付费',
    },
  ]),
}

export default config
