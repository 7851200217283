var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "person-list-container" },
    [
      _c("SearchForm", {
        attrs: {
          "label-width": 70,
          query: _vm.query,
          "search-column": _vm.searchColumn,
        },
        on: { getTableData: _vm.getTableData, resetForm: _vm.resetForm },
      }),
      _c("TablePaging", {
        attrs: {
          "button-list": _vm.buttonList,
          "column-list": _vm.columnList,
          loading: _vm.loading,
          "max-height": _vm.maxHeight,
          "page-info": _vm.pageInfo,
          selection: true,
          "table-data": _vm.list,
        },
        on: {
          handleAddLabel: _vm.handleAddLabel,
          handleBatchRemove: _vm.handleBatchRemove,
          handleBulkMute: _vm.handleBulkMute,
          handleCancelMute: _vm.handleCancelMute,
          handleCurrentChange: _vm.handleCurrentChange,
          handleMute: _vm.handleMute,
          handleRemove: _vm.handleRemove,
          handleSelectionChange: _vm.handleSelectionChange,
          handleSetAdmin: _vm.handleSetAdmin,
          handleSetVest: _vm.handleSetVest,
        },
        scopedSlots: _vm._u([
          {
            key: "username",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  {
                    staticClass: "username",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleUserInfo(row)
                      },
                    },
                  },
                  [
                    _c("el-image", { attrs: { src: row.head } }),
                    _c("el-link", [_vm._v(_vm._s(row.name))]),
                    _c("el-image", {
                      staticClass: "level",
                      attrs: { src: row.group_ico_src },
                    }),
                    _c(
                      "div",
                      [
                        row.isVest
                          ? _c("el-tag", [_vm._v("[马甲]")])
                          : _vm._e(),
                        row.adminRole
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v(
                                " [" + _vm._s(row.adminRole.role_name) + "] "
                              ),
                            ])
                          : _vm._e(),
                        row.isBanned
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("[被禁言]"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "login_ip",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("div", [_vm._v(_vm._s(row.last_login_ip))]),
                _c("div", { staticStyle: { color: "red" } }, [
                  _vm._v(_vm._s(row.role_str)),
                ]),
              ]
            },
          },
          {
            key: "phone",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("div", [_vm._v(_vm._s(row.phone))]),
                _c("div", [_vm._v(_vm._s(row.phone_address))]),
              ]
            },
          },
          {
            key: "status_str",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("span", { domProps: { innerHTML: _vm._s(row.status_str) } }),
              ]
            },
          },
        ]),
      }),
      _c("UserInfoDialog", {
        ref: "userInfoDialog",
        on: {
          getTableData: _vm.getTableData,
          handleCancelMute: _vm.handleCancelMute,
        },
      }),
      _c("MuteDialog", {
        ref: "muteDialog",
        on: { getTableData: _vm.getTableData },
      }),
      _c("BatchDialog", {
        ref: "batchDialog",
        on: { getTableData: _vm.getTableData },
      }),
      _c("AdminConfigDialog", {
        ref: "adminConfigDialog",
        on: { getTableData: _vm.getTableData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }