<template>
  <div class="person-list-container">
    <SearchForm
      :label-width="70"
      :query="query"
      :search-column="searchColumn"
      @getTableData="getTableData"
      @resetForm="resetForm"
    />
    <!-- 表格 -->
    <TablePaging
      :button-list="buttonList"
      :column-list="columnList"
      :loading="loading"
      :max-height="maxHeight"
      :page-info="pageInfo"
      :selection="true"
      :table-data="list"
      @handleAddLabel="handleAddLabel"
      @handleBatchRemove="handleBatchRemove"
      @handleBulkMute="handleBulkMute"
      @handleCancelMute="handleCancelMute"
      @handleCurrentChange="handleCurrentChange"
      @handleMute="handleMute"
      @handleRemove="handleRemove"
      @handleSelectionChange="handleSelectionChange"
      @handleSetAdmin="handleSetAdmin"
      @handleSetVest="handleSetVest"
    >
      <!-- 姓名 -->
      <template #username="{ row }">
        <div class="username" @click.stop="handleUserInfo(row)">
          <el-image :src="row.head" />
          <el-link>{{ row.name }}</el-link>
          <el-image class="level" :src="row.group_ico_src" />
          <div>
            <el-tag v-if="row.isVest">[马甲]</el-tag>
            <el-tag v-if="row.adminRole" type="success">
              [{{ row.adminRole.role_name }}]
            </el-tag>
            <el-tag v-if="row.isBanned" type="danger">[被禁言]</el-tag>
          </div>
        </div>
      </template>
      <!-- 最后登录ip -->
      <template #login_ip="{ row }">
        <div>{{ row.last_login_ip }}</div>
        <div style="color: red">{{ row.role_str }}</div>
      </template>
      <!-- 手机号 -->
      <template #phone="{ row }">
        <div>{{ row.phone }}</div>
        <div>{{ row.phone_address }}</div>
      </template>
      <!-- 状态 -->
      <template #status_str="{ row }">
        <span v-html="row.status_str"></span>
      </template>
    </TablePaging>
    <UserInfoDialog
      ref="userInfoDialog"
      @getTableData="getTableData"
      @handleCancelMute="handleCancelMute"
    />
    <!-- 禁言-对话框 -->
    <MuteDialog ref="muteDialog" @getTableData="getTableData" />
    <!-- 批量-对话框 -->
    <BatchDialog ref="batchDialog" @getTableData="getTableData" />
    <!-- 设置用户权限 -->
    <AdminConfigDialog ref="adminConfigDialog" @getTableData="getTableData" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import config from './config'
  import {
    getUserList,
    cancleUserMute,
    cancleBatchUserBanned,
    batchVerstUser,
    batchUserDelete,
  } from '@/api/user'
  import SearchForm from '@/components/SearchForm'
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  import UserInfoDialog from './components/userInfoDialog'
  import MuteDialog from './components/muteDialog'
  import BatchDialog from './components/batchDialog'
  import AdminConfigDialog from './components/adminConfigDialog'

  export default {
    components: {
      SearchForm,
      TablePaging,
      UserInfoDialog,
      MuteDialog,
      BatchDialog,
      AdminConfigDialog,
    },
    mixins: [tableMixins],
    data() {
      return config
    },
    computed: {
      ...mapGetters({
        config: 'user/config',
      }),
      searchColumn() {
        return [
          {
            label: '账户类型',
            prop: 'type',
            type: 'select',
            clearable: false,
            selectList: this.typeSelect,
          },
          {
            label: '登记类型',
            prop: 'searchType',
            type: 'select',
            clearable: false,
            isHide: this.query.type != 1,
            selectList: this.searchTypeSelect,
          },
          {
            label: '状态',
            prop: 'registerType',
            type: 'select',
            clearable: false,
            isHide: this.config.register_type != 3,
            selectList: this.registerSelectList,
          },
          {
            label: '关键字',
            prop: 'keywords',
            isHide: this.query.type != 1,
          },
          {
            label: '用户名称',
            prop: 'user',
            type: 'select',
            clearable: false,
            isHide: !(
              this.config.register_type == 1 && this.config.register_type == 3
            ),
            selectList: this.userList,
          },
        ]
      },
    },
    created() {
      this.getTableData()
    },
    methods: {
      // 查询信息流列表
      getTableData(value) {
        let params = {
          page: this.pageInfo.page,
          step: this.pageInfo.step,
        }
        params = Object.assign(
          {},
          value === undefined ? this.query : value,
          params
        )
        this.loading = true
        getUserList(params).then((res) => {
          this.loading = false
          this.list = res.list || []
          this.pageInfo.count = res.total
        })
      },
      // 获取个人信息
      handleUserInfo(row) {
        this.$refs.userInfoDialog.handleOpen(row.user_id)
      },
      // 禁言/禁止私聊
      handleMute(row, index, buttonName) {
        this.$refs.muteDialog.handleOpen(buttonName, row)
      },
      // 取消禁言/恢复私聊
      handleCancelMute(row, index, buttonName) {
        const params = { id: row.user_id }
        this.$confirm(`此操作将${buttonName}当前这条数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            let res
            if (buttonName === '取消禁言') {
              res = await cancleUserMute(params)
            } else {
              res = await cancleBatchUserBanned(params)
            }
            this.$message({
              type: res.success ? 'success' : 'error',
              message: res.success ? `${buttonName}成功` : `${buttonName}失败`,
            })
            if (res.success) this.getTableData()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      },
      // 批量禁言
      handleBulkMute(row, index, buttonName) {
        this.$refs.muteDialog.handleOpen(buttonName)
      },
      // 添加标签
      handleAddLabel(row, index, buttonName) {
        if (!this.isCheck()) return
        console.log(row, index, buttonName)
      },
      // 设为马甲
      async handleSetVest(row, index, buttonName) {
        if (!this.isCheck()) return
        this.$confirm(`此操作会将选中数据${buttonName}, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            const params = {
              vest_ids: this.selectionList
                .map((item) => item.user_id)
                .join(','),
            }
            const res = await batchVerstUser(params)
            this.$message({
              type: res.success ? 'success' : 'error',
              message: res.success ? `设置成功` : `设置失败`,
            })
            if (res.success) this.getTableData()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      },
      // 设为管理员
      handleSetAdmin() {
        if (!this.isCheck()) return
        this.$refs.adminConfigDialog.handleOpen(
          '设置用户权限',
          this.selectionList
        )
      },
      // 批量删除
      handleBatchRemove(row, index, buttonName) {
        this.$refs.batchDialog.handleOpen(buttonName)
      },
      // 删除
      handleRemove(row, index, buttonName) {
        if (!this.isCheck()) return
        this.$confirm(
          `该操作仅将用户从APP用户列表删除，如依赖其他平台，还需要到对应平台后台进行删除。`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(async () => {
            const params = {
              user_ids: this.selectionList
                .map((item) => item.user_id)
                .join(','),
            }
            const res = await batchUserDelete(params)
            this.$message({
              type: res.success ? 'success' : 'error',
              message: res.success ? `${buttonName}成功` : `${buttonName}失败`,
            })
            if (res.success) this.getTableData()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      },
      // 是否选中数据
      isCheck() {
        if (this.selectionList.length === 0) {
          this.$message.error('请至少选择一条数据进行操作')
          return false
        }
        return true
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.person-list';
  #{$base}-container {
    ::v-deep .table {
      .username {
        display: flex;
        align-items: center;
        .el-link {
          margin: 0 10px;
        }
        .level {
          width: 30px;
          height: 15px;
          margin-right: 10px;
        }
      }
    }
  }
</style>
