<template>
  <el-dialog
    append-to-body
    :before-close="handleClose"
    :title="title"
    :visible.sync="dialogVisible"
    width="40%"
  >
    <Form :column="column" :label-width="100" :query="query" />
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="onSubmit">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getRoleList, adminUserAdd } from '@/api/user'
  import Form from '@/components/Form'
  export default {
    components: {
      Form,
    },
    data() {
      return {
        title: null,
        dialogVisible: false,
        loading: false,
        roleList: [],
        query: {
          user_id: null,
          role: null,
        },
      }
    },
    computed: {
      column() {
        return [
          {
            label: '选择权限',
            prop: 'role',
            type: 'radio',
            optionLabel: 'name',
            clearable: false,
            list: this.roleList,
          },
        ]
      },
    },
    methods: {
      handleOpen(title, list) {
        this.title = title
        this.query.user_id = list.map((item) => item.user_id).join(',')
        this.dialogVisible = true
        this.getRoleList()
      },
      async getRoleList() {
        const res = await getRoleList()
        if (res.success) {
          this.roleList = res.list
        }
      },
      // 提交
      async onSubmit() {
        if (this.loading) return
        this.loading = true
        let params = Object.assign({}, this.query)
        const res = await adminUserAdd(params)
        this.loading = false
        if (res.success) {
          this.cancel()
          this.$emit('getTableData')
          this.$message.success('设置成功')
        } else {
          this.$message.error('设置失败')
        }
      },
      handleClose(done) {
        done()
        this.cancel()
      },
      // 取消
      cancel() {
        this.dialogVisible = false
        setTimeout(() => {
          for (let key in this.query) {
            this.query[key] = null
          }
        }, 500)
      },
    },
  }
</script>
