var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        "before-close": _vm.handleClose,
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: "40%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("Form", {
        attrs: { column: _vm.column, "label-width": 100, query: _vm.query },
      }),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v(" 确 定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }